<template>
  <div class="homepage">
    <!--顶部轮播图 -->
    <div class="banner">
      <img
        class="banner-img"
        src="../../../assets/images/banner/banner_b.png"
        alt=""
      />
      <!-- <img class="banner-img" :src="Banner" alt=""> -->
      <!-- 红娘热线 -->
      <div class="phone">
        <span class="matchmaker">红娘热线：</span>
        <span class="number">400 8234 521</span>
        <button class="btn" @click="fuwu">申请会员服务</button>
      </div>
    </div>
    <!-- 猎婚vip服务 -->
    <div class="vip">
      <p class="service">也在猎婚VIP服务</p>
      <!-- 服务列表 -->
      <div class="vipList">
        <div class="item">
          <div class="vipImg">
            <img src="../../../assets/images/server/item5.png" alt="" />
          </div>
          <p class="p1">线下门店全直营</p>
          <p class="p2">不做加盟，打造一流品质服务</p>
        </div>
        <!-- 2 -->
        <div class="item">
          <div class="vipImg">
            <img src="../../../assets/images/server/item1.png" alt="" />
          </div>
          <p class="p1">红娘服务一对一</p>
          <p class="p2">资深红娘团队，专业婚恋测评</p>
        </div>
        <!--3  -->
        <div class="item">
          <div class="vipImg">
            <img src="../../../assets/images/server/item2.png" alt="" />
          </div>
          <p class="p1">全国会员可共享</p>
          <p class="p2">1亿优质会员，专业匹配推荐</p>
        </div>
        <!-- 4 -->
        <div class="item">
          <div class="vipImg">
            <img src="../../../assets/images/server/item3.png" alt="" />
          </div>
          <p class="p1">门店相亲保平安</p>
          <p class="p2">多彩相亲派对，安全约见场所</p>
        </div>
        <!-- 5 -->
        <div class="item">
          <div class="vipImg">
            <img src="../../../assets/images/server/item4.png" alt="" />
          </div>
          <p class="p1">身份验证百分百</p>
          <p class="p2">资料严格审查，安全隐私保护</p>
        </div>
      </div>
    </div>
    <!-- 精品优质会员 -->
    <div class="high">
      <p class="yzhui">精选优质会员</p>
      <div class="member">
        <!-- :class="{ Active: isChecked == 1 }" -->
        <span
          class="red white"
          :class="isChecked == 1 ? 'Active' : ''"
          @click="btnclick(1)"
          >只看女会员</span
        >
        <!-- :class="{ isActive: isChecked == -1 }" -->
        <span
          class="blue bluewhite"
          :class="isChecked == -1 ? 'isActive' : ''"
          @click="btnclick(-1)"
          >只看男会员</span
        >
      </div>
      <!-- 会员照片 -->
      <div class="highPhoto">
        <div
          class="photo"
          v-for="(item, index) in searchdata.slice(0, 5)"
          :key="index"
        >
          <img :src="item.Img" alt="" />
          <div class="means" @click="details(item)">
            <p class="nickName">{{ item.nickName }}</p>
            <p class="age">
              <span>{{ item.age }}岁</span> &nbsp;
              <span v-if="item.height">{{ item.height }}cm</span>
            </p>
            <p class="work">
              职业:
              <span v-if="item.occBigCategory">{{ item.occBigCategory }}</span>
              <span v-else>--</span>
            </p>
            <!-- <p class="work" v-if="item.monthIncome">收入: <span>{{ item.monthIncome == 0 ? '':'' }}</span></p> -->
            <p class="work">
              收入: <span v-if="item.monthIncome">3000元以上</span>
              <span v-else>--</span>
            </p>
            <p class="want" style="font-size: 12px">
              <img src="../../../assets/images/server/love.png" alt="" />
              我想认识{{ item.sex == 1 ? "她" : "他" }}
            </p>
            <!-- 她(他)  -->
          </div>
        </div>
      </div>
      <!-- 查看更多 -->
      <div class="more" @click="more()">查看更多>></div>
      <!-- 我要vip服务 -->
      <div class="vipserve" @click="ask()">我要vip服务</div>
    </div>
    <!-- 成功故事 -->
    <div class="success">
      <p>成功故事</p>
      <div class="list">
        <div
          class="successlist"
          v-for="item in listData"
          :key="item.id"
          @click="GrowthStory(item)"
        >
          <p class="name">{{ item.title }}</p>
          <p class="may">{{ item.content }}</p>
          <div class="successimg">
            <img :src="item.htImg" alt="" />
            <!-- <img :src="item.mainImg" alt="" /> -->
          </div>
          <div class="start"><span>会员状态:</span> <span>已交往</span></div>
        </div>
      </div>
      <div class="more" @click="gd">查看更多>></div>
    </div>
    <!-- 红娘风采 -->
    <div class="teacher">
      <p>红娘风采</p>
      <div class="teacherlist">
        <div class="teacheritem">
          <div class="photo">
            <img src="../../../assets/images/banner/hongZ-3.png" alt="" />
          </div>
          <p>罗老师</p>
          <div class="opinion">
            婚恋观点:
            <span>
              婚姻生活本来就是重复一样的事情生活着，再热烈的情感都总有回归到平淡甚至枯燥乏味的那一刻。要知道平平淡淡的感情才是长久的，不管在一起多久，都要时常向对方表达爱意。这样才能使得感情更加长久。
            </span>
          </div>
        </div>
        <!-- 2 -->
        <div class="teacheritem">
          <div class="photo">
            <img src="../../../assets/images/banner/hongZ-2.png" alt="" />
          </div>
          <p>梁老师</p>
          <div class="opinion">
            婚恋观点:
            <span>
              我们要通过恋爱不断地去寻找真正适合自己的人，认识到自身的不足，不断提升自己的恋爱观。当你拥有独立的思想意识，也知道什么样的人更适合自己，你反而更容易得到幸福。
             
            </span>
          </div>
        </div>
        <!-- 3 -->
        <div class="teacheritem">
          <div class="photo">
            <img src="../../../assets/images/banner/hongZ-4.png" alt="" />
          </div>
          <p>谢老师</p>
          <div class="opinion">
            婚恋观点:
            <span>
              婚姻和爱情所不同的：婚姻里要有爱情，爱情可以是浪漫的，但是婚姻一定是接地气的。婚姻是要好好过日子。
              婚姻是过日子，我们就不可能成天的风花雪月；我们要和一个人好好的相处，所以一定要认真的去选择一个相处在一起很舒服的人。
            </span>
          </div>
        </div>
        <!-- 4 -->
        <div class="teacheritem">
          <div class="photo">
            <img src="../../../assets/images/banner/hongZ-1.png" alt="" />
          </div>
          <p>黄老师</p>
          <div class="opinion">
            婚恋观点:
            <span>
              恋爱是寻找志同道合、白头偕老的终生伴侣，不是安慰解闷、满足生活需要，只有正确的恋爱观，才能找到真正的爱情和幸福。
              婚姻是一种责任，和彼此的奉献、理解关怀。这样婚姻才能幸福，不要计较谁付出多少，否则那就不是爱情，那是利益交换。
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 直营门店 -->
    <div class="direct">
      <p class="zyShop">直营门店</p>
      <div class="directlist">
        <div class="directlist-Shop-a">
          <p class="title-a">佛山也在网顺德门店</p>
        </div>
        <div class="directlist-Shop-b">
          <p class="title-b">佛山也在网禅城门店</p>
        </div>
        <div class="directimg-Shop">
          <div class="Shop-box" @click="jump">
            <span>查看详情</span>
            <img
              src="../../../assets/images/banner/fanhuishangyiji.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 申请服务 -->
    <div class="sqfw" v-show="server">
      <div class="card">
        <div class="title">
          <p>申请服务</p>
          <img
            @click="Children()"
            src="../../../assets/images/tubiao/close.d79c63e.gif"
            alt=""
          />
        </div>
        <p class="qr">{{ Title }}</p>
        <!-- 是否联系红娘申请vip服务 -->
        <div class="btn" @click="Services()">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Information } from "@/api/my/my.js";
import { infocur } from "@/api/login/login.js"; //browsing
import { ApplicationServices, subTk } from "@/api/member/member.js";
import local from "@/api/common/local.js";
import { ossL } from "@/api/common/oss/ossL.js";
import { storyList } from "@/api/member/member.js";
export default {
  data() {
    return {
      server: false,
      isChecked: 1,
      searchdata: [],
      sex: 1,
      givesex: "",
      listData: [],
      Title: "",
      index: "",
      Banner: "@/assets/images/banner/banner_b.png",
    };
  },
  created() {
    this.getxinxi();
    this.subTkL();
    this.storyList();
  },
  methods: {
    // 关闭弹窗
    Children() {
      this.index = "";
      this.server = false;
    },
    // 点击查看详情
    async details(item) {
      this.index = item.id;
      this.Title = "想认识TA吗？让我们为您牵手幸福吧";
      this.server = true;
    },
    //临时令牌
    async subTkL() {
      const { code, data } = await subTk();
      console.log("00");
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    fuwu() {
      this.index = "";
      this.Title = "是否联系红娘申请VIP服务";
      this.server = true;
      this.subTkL();
    },
    // 获取当前用户
    async getxinxi() {
      this.$store.state.directIndex = 0
      this.searchdata = [];
      let active = local.get("access_token");
      const { code, data } = await infocur(active);
      if (code == 0) {
        this.givesex = data.user.sex;
        if (this.givesex == 0) {
          this.isChecked = 1;
          this.sex = 1;
        } else {
          this.isChecked = -1;
          this.sex = 0;
        }
        //分数据
        let Data = {
          sex: this.sex,
        };
        const z = await Information(Data);
        if (z.code == 0) {
          z.data.records.map((item) => {
            this.searchdata.push(
              Object.assign({}, item, {
                Img: ossL(item.avatar),
              })
            );
          });
        }
      }
    },
    // 查看更多
    async more() {
      this.index = "";
      this.Title = "想了解更多优质会员吗？让我们来告诉您吧";
      this.server = true;
    },

    //选项卡
    async btnclick(indexLL) {
      this.searchdata = [];
      if (indexLL == 1) {
        this.sex = 1;
        this.isChecked = 1;
      } else {
        this.sex = 0;
        this.isChecked = -1;
      }
      let Data = {
        sex: this.sex,
      };
      const { code, data } = await Information(Data);
      if (code == 0) {
        data.records.map((item) => {
          this.searchdata.push(
            Object.assign({}, item, {
              Img: ossL(item.avatar),
            })
          );
        });
      }
    },
    gd() {
      this.index = "";
      this.Title = "想了解更多成功故事吗？让我们来告诉您吧";
      this.server = true;
    },
    async Services() {
      let a = {
        targetId: this.index,
      };
      const { code, msg } = await ApplicationServices(a);
      if (code == 0) {
        this.$message({
          message: msg,
          type: "success",
          offset: 100,
        });

        this.server = false;
        this.index = "";
      } else if (code == 1) {
        this.$message({
          message:
            "会员服务已经申请成功，不能重复申请，工作人员会尽快联系您，请耐心等待",
          type: "error",
          offset: 100,
        });
        this.server = false;
        this.index = "";
      }
      this.subTkL();
    },
    ask() {
      this.index = "";
      this.Title = "是否联系红娘申请VIP服务";
      this.server = true;
    },
    //成长故事
    async storyList() {
      const { code, data } = await storyList();
      if (code == 0) {
        // this.listData.push(data[i]);
        const l = [];
        data.map(async (item) => {
          l.push(
            Object.assign({}, item, {
              show: true,
              htImg: ossL(item.mainImg),
            })
          );
        });
        this.listData = l.slice(0, 4);
        console.log("数据说是", l);
      }
    },
    jump() {
      let rel = this.$router.resolve({
        path: "/directStore/_address",
      });
      window.open(rel.href, "_blank");
    },
    GrowthStory(item) {
      let rel = this.$router.resolve({
        path: "/directStore/home/story",
        // path: "/directStore/story",
        query: {
          Story: item.id,
        },
      });
      window.open(rel.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.homepage {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 1200px;
}
.banner {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-position: center center;
  img {
    width: 100%;
    height: 100%;
    @media screen {
      min-width: 1920px;
    }
  }
  .phone {
    width: 370px;
    height: 148px;
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    left: 60%;
    top: 50%;
    .matchmaker {
      display: inline-block;
      width: 120px;
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      margin-left: 59px;
      margin-top: 20px;
    }
    .number {
      width: 130px;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
    }
    .btn {
      border: none;
      width: 320px;
      height: 50px;
      background: linear-gradient(90deg, #ffa1a7 0%, #fd686e 100%);
      border-radius: 6px;
      margin-left: 25px;
      margin-top: 20px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 50px;
    }
  }
}

.vip {
  width: 100%;
  height: 492px;
  background: #f7f7f7;
  .service {
    height: 42px;
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: #555555;
    line-height: 42px;
    margin-top: 80px;
  }
  .vipList {
    display: flex;
    justify-content: center;
    .item:hover .p1 {
      font-size: 18px;
      color: #fd686e;
      transition: 0.35s;
      transition-property: all;
      transition-duration: 0.35s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
    .item:hover .p2 {
      color: #fd686e;
    }
    .item:hover img {
      width: 150px;
      height: 150px;
    }
    .item {
      width: 200px;
      height: 260px;
      margin-top: 102px;
      margin-right: 42px;
      cursor: pointer;
      .vipImg {
        width: 160px;
        height: 160px;
        // background: pink;
        border-radius: 50%;
        margin-left: 20px;
      }

      .p1 {
        height: 22px;
        font-size: 16px;
        color: #333;
        line-height: 22px;
        margin-top: 18px;
        margin-bottom: 5px;
        text-align: center;
      }

      .p2 {
        // width: 182px;
        height: 20px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        color: #999999;
        line-height: 20px;
      }
    }
  }
}
.high {
  width: 100%;
  height: 736px;
  .yzhui {
    margin-top: 80px;
    height: 42px;
    font-size: 30px;
    color: #555555;
    line-height: 42px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }
  // p {
  //   margin: 0 auto;

  // }
  .member {
    width: 100%;
    height: 38px;
    justify-content: center;
    display: flex;

    .red {
      cursor: pointer;
      width: 142px;
      background: #fff;
      border: 1px solid #fd686e;
      border-radius: 2px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 38px;
      color: #fd686e;
      text-align: center;
      margin-right: 30px;
    }

    .bluewhite {
      cursor: pointer;
      display: inline-block;
      width: 140px;
      border: 1px solid #7badff;
      border-radius: 2px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 38px;
      color: #7badff;
      text-align: center;
    }

    .isActive {
      background: #7badff;
      color: #fff;
    }
    .Active {
      background: #fd686e;
      color: #fff;
    }
  }
  .highPhoto {
    cursor: pointer;
    width: 1200px;
    margin: 0 auto;
    height: 220px;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    overflow: hidden;
    .photo {
      width: 220px;
      height: 220px;
      // background: pink;
      margin-right: 20px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .photo:hover .means {
      opacity: 1;
      transform: translateY(0);
    }
    .means {
      position: absolute;
      bottom: 0;
      width: 220px;
      height: 220px;
      background: rgba(0, 0, 0, 0.4);
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
      // overflow: hidden;
      opacity: 0;
      box-sizing: border-box;
      transition: 0.7s;
      transform: translateY(100%);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .nickName {
        margin-top: 24px;
        font-size: 22px;
      }
      p {
        color: #fff;
        margin-top: 0px;
        margin-bottom: 0px;
      }
      .age {
        font-size: 18px;
        height: 25px;
        //         opacity: 0.6;
        // border: 1px solid #DEDEDE;
        border-bottom: 2px solid rgba(222, 222, 222, 0.6);
      }
      .work {
        font-size: 14px;
      }
      .want {
        font-size: 12px;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
          width: 16px;
          height: 15px;
        }
      }
    }
  }
  .more {
    margin: 0 auto;
    width: 200px;
    height: 46px;
    background: #fd686e;
    border-radius: 25px;
    text-align: center;
    line-height: 46px;
    margin-top: 50px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
  }
  .vipserve:hover {
    background: linear-gradient(90deg, #ffa1a7 0%, #fd686e 100%);
    color: #fff;
    border: none;
  }
  .vipserve {
    cursor: pointer;
    margin: 0 auto;
    width: 320px;
    height: 50px;
    border-radius: 6px;
    border: 1px solid #fd686e;
    color: #fd686e;
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    margin-top: 40px;
  }
}
.success {
  width: 100%;
  height: 642px;
  background: #f7f7f7;
  p {
    width: 120px;
    height: 42px;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #555555;
    line-height: 42px;
    margin: 0 auto;
    margin-top: 70px;
  }
  .more {
    margin: 0 auto;
    width: 200px;
    height: 46px;
    background: #fd686e;
    border-radius: 25px;
    text-align: center;
    line-height: 46px;
    margin-top: 50px;
    font-size: 16px;
    color: #fff;
    // font-family: PingFangSC-Regular, PingFang SC;
    // font-weight: 400;
    cursor: pointer;
  }
}
.list {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  .successlist {
    width: 280px;
    height: 340px;
    margin-right: 20px;
    cursor: pointer;
    background: #ffffff;
    border-radius: 2px;
    .name {
      width: 280px;
      text-align: center;
      font-size: 24px;
      color: #555555;
      line-height: 33px;
      margin-top: 24px;
      margin-bottom: 2px;
    }
    .successimg {
      width: 248px;
      height: 186px;
      background: pink;
      margin: 0 auto;
      img {
        width: 248px;
        height: 186px;
      }
    }
    .may {
      width: 226px;
      height: 20px;
      font-size: 14px;
      color: #adadad;
      line-height: 20px;
      margin: 0 auto;
      margin-bottom: 8px;
      // overflow: hidden;
      // // 下面是显示几行写数字几就行
      // -webkit-line-clamp: 1;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // text-overflow: ellipsis;
      //     white-space: nowrap;

      overflow: hidden;

      text-overflow: ellipsis;

      white-space: nowrap; //文本不换行，这样超出一行的部分被截取，显示...
    }
    .start {
      margin: 16px 16px 24px 150px;
    }
  }
}
.teacher {
  width: 100%;
  // height: 865px;
  height: 820px;
  p {
    width: 120px;
    height: 42px;
    font-size: 30px;
    color: #555555;
    line-height: 42px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .more {
    margin: 0 auto;
    width: 200px;
    height: 46px;
    background: #fd686e;
    border-radius: 25px;
    text-align: center;
    line-height: 46px;
    margin-top: 50px;
    font-size: 16px;
    color: #fff;

    cursor: pointer;
  }
  .teacherlist {
    display: flex;
    justify-content: center;

    .teacheritem {
      width: 266px;
      // height: 510px;
      margin-right: 32px;
      .photo {
        width: 266px;
        height: 371px;
      }
      p {
        // width: 36px;
        height: 25px;
        font-size: 18px;

        color: #333333;
        line-height: 25px;
        margin: 16px 0px;
      }
      .opinion {
        width: 231px;
        height: 75px;
        font-size: 14px;
        color: #666666;
        line-height: 25px;
        // line-height: 20px;
        text-align: justify;
      }
    }
  }
}
.sqfw {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  .card {
    position: fixed;
    top: 256px;
    left: 40%;
    width: 380px;
    height: 212px;
    background: #ffffff;
    border-radius: 4px;
    .title {
      // width: 440px;
      height: 44px;
      background: #f5f5f5;
      font-size: 14px;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: 12px;
        height: 12px;
        position: absolute;
        right: 16px;
        cursor: pointer;
      }
    }
    .qr {
      text-align: center;
      font-size: 14px;
      color: #333;
      margin-top: 38px;
      // line-height: 40px;
    }
    .btn {
      margin: 0 auto;
      text-align: center;
      cursor: pointer;
      margin-top: 34px;
      width: 180px;
      height: 40px;
      background: #fd686e;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 14px;
    }
  }
}
.direct {
  width: 100%;
  height: 640px;
  background: #f7f7f7;

  .zyShop {
    width: 120px;
    height: 42px;
    font-size: 30px;
    color: #555555;
    line-height: 42px;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 60px;
  }
  .directlist {
    display: flex;
    justify-content: center;
    .directlist-Shop-a {
      background: url("../../../assets/images/banner/Shop-a.png") no-repeat;
      width: 280px;
      height: 312px;
      margin-right: 20px;
      display: flex;
      align-items: flex-end;
      .title-a {
        font-size: 18px;
        color: #ffffff;
        width: 280px;
        height: 38px;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .directlist-Shop-b {
      background: url("../../../assets/images/banner/Shop-b.png") no-repeat;
      width: 280px;
      height: 312px;
      margin-right: 20px;
      display: flex;
      align-items: flex-end;
      .title-b {
        font-size: 18px;
        color: #ffffff;
        width: 280px;
        height: 38px;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .directimg-Shop {
      background: url("../../../assets/images/banner/zyShop.png") no-repeat;
      width: 580px;
      height: 312px;
      display: flex;
      justify-content: center;
      align-items: center;
      .Shop-box {
        width: 282px;
        height: 60px;
        border: 1px solid #fff;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 20px;
          color: #fff;
          margin-right: 10px;
        }
      }
    }
  }

  .more {
    margin: 0 auto;
    width: 200px;
    height: 46px;
    background: #fd686e;
    border-radius: 25px;
    text-align: center;
    line-height: 46px;
    margin-top: 50px;
    font-size: 16px;
    color: #fff;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    cursor: pointer;
  }
}
</style>
